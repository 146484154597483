// 开发环境域名

// const host_development = 'https://likeshopb2cplusdoukai.yixiangonline.com'
const host_development = 'https://admin.gdbadatong.com'
// const host_development = 'https://admin.nanmofo.cn'

export default {
    // 版本
    version: '1.8.0',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW',
}
